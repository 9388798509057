import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
import './static/css/custom.css';
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/plugins/unicons';
import '@/core/components/custom';
import '@/i18n/config';
import Cookies from 'js-cookie';
import axios from "axios";
import queryString from 'query-string';
import { message } from "ant-design-vue";

window._ = require('lodash');
const dayjs = require("dayjs");

let baseUrl = "";

if(process.env.NODE_ENV === 'production') {
  baseUrl = 'https://api.cycledepo.com';
}else{
  baseUrl = 'http://cycleonrent.test';
}

axios.defaults.baseURL = `${baseUrl}/api/admin/`;

axios.interceptors.request.use(function (config) {
    config.headers.Accept = 'application/json'
    if(Cookies.get('admin_token')) {
        config.headers.Authorization = `"Bearer ${Cookies.get('admin_token')}`
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  if("success"in response.data && "message" in response.data) {
    if(response.data["success"] == true) {
      message.success(response.data["message"]);
    }else if(response.data["success"] == false) {
      message.error(response.data["message"]);
    }
  }
  return response;
}, function (error) {

  console.error(error);

  if(error?.response?.status == 401) {
    router.push({
      name: 'login'
    });

  }
  if(error?.response?.status == 422 && error?.response?.data?.message) { message.error(error.response.data.message); }

  if(error?.response?.status == 500) { message.error("There was some server side error."); }

  return Promise.reject(error);
});


// window._ = _;

let currencyFormat = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
  maximumFractionDigits: 2, 
  minimumFractionDigits: 0
});

let precentFormat = new Intl.NumberFormat('en-IN', {
  style: 'percent',
  maximumFractionDigits: 2, 
  minimumFractionDigits: 0
});

const humanify = (type, value) => {
  switch(type) {
    case "currency":
      return currencyFormat.format(value);
    case "percent":
      return precentFormat.format(value / 100);
  }
};

app.config.productionTip = false;
app.use(store);
app.use(router);
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$humanify = humanify;
app.config.globalProperties.$queryString = queryString;

app.mount('#app');