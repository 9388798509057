import mutations from './mutations';
import Cookies from 'js-cookie';

const state = () => ({
  admin_token: Cookies.get('admin_token'),
  admin: JSON.parse(Cookies.get('admin') ?? null),
  loading: false,
  error: null,
});

const actions = {
  async logIn({ commit }, {token, admin}) {
    Cookies.set('admin_token', token);
    Cookies.set('admin', JSON.stringify(admin));
    commit('setAdminToken', token);
    commit('setAdmin', admin);
  },
  async logOut({ commit }) {
    Cookies.remove('admin_token');
    Cookies.remove('admin');
    commit('setAdminToken', undefined);
    commit('setAdmin', undefined);
  },
};

export default {
  namespaced: false,
  state,
  actions,
  mutations,
};
