import PageRoutes from './PageRoutes';

const routes = [
  // {
  //   path: '',
  //   name: 'blank-page',
  //   component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/pages/BlankPage.vue'),
  // },

  // {
  //   path: 'users',
  //   name: 'users',
  //   component: () => import(/* webpackChunkName: "Dashboard" */ '@/view/pages/User/Users.vue'),
  // },

  ...PageRoutes,
];

export default routes;
