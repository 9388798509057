<template>
    <span class="tag" :style="{ backgroundColor: color }">
        <slot />
    </span>
</template>
<script>
export default {
    name: 'Tag',
    props: [
        'color'
    ]
}
</script>
<style scoped>
.tag{
    font-size: 14px;
    font-weight: normal;
    padding: 2px 7px;
    text-transform: capitalize;
    color: white;
    border-radius: 6px;
    padding: 4px 12px;
}
</style>