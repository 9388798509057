export default [

  {
    path: '/',
    name: 'home',
    // component: () => import('@/view/pages/BlankPage.vue'),
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/view/pages/Dashboard/Dashboard.vue'),
  },

  {
    path: '/user',
    name: 'users',
    component: () => import('@/view/pages/User/Users.vue'),
  },
  {
    path: '/user/create',
    name: 'user.create',
    component: () => import('@/view/pages/User/CreateUser.vue'),
  },
  {
    path: '/user/:id',
    name: 'user.show',
    component: () => import('@/view/pages/User/ShowUser.vue'),
  },
  {
    path: '/mechanics',
    name: 'mechanics',
    component: () => import('@/view/pages/Mechanic/Mechanics.vue'),
  },
  {
    path: '/mechanic/create',
    name: 'mechanic.create',
    component: () => import('@/view/pages/Mechanic/CreateMechanic.vue'),
  },
  {
    path: '/mechanic/:id',
    name: 'mechanic.show',
    component: () => import('@/view/pages/Mechanic/ViewMechanic'),
  },
  {
    path: '/mechanic/transactions',
    name: 'mechanic.transactions',
    component: () => import('@/view/pages/MechanicTransactions/MechanicTransactions.vue'),
  },
  {
    path: '/service-appointments',
    name: 'service_appointments',
    component: () => import('@/view/pages/ServiceAppointment/ServiceAppointments.vue'),
  },

  {
    path: '/service-appointment/:id',
    name: 'service_appointment.show',
    component: () => import('@/view/pages/ServiceAppointment/ShowServiceAppointment.vue'),
  },


  {
    path: '/m-inventory-requests',
    name: 'm_inventory_requests',
    component: () => import('@/view/pages/MInventoryRequest/MInventoryRequests.vue'),
  },

  {
    path: '/m-inventory-requests/:id',
    name: 'm_inventory_requests.show',
    component: () => import('@/view/pages/MInventoryRequest/ShowMInventoryRequest.vue'),
  },

  {
    path: '/m-inventory-items',
    name: 'm_inventory_items',
    component: () => import('@/view/pages/MInventoryItem/MInventoryItems.vue'),
  },
  {
    path: '/m-inventory-items/create',
    name: 'm_inventory_items.create',
    component: () => import('@/view/pages/MInventoryItem/CreateMInventoryItem.vue'),
  },
  {
    path: '/m-inventory-items/:id',
    name: 'm_inventory_items.edit',
    component: () => import('@/view/pages/MInventoryItem/CreateMInventoryItem.vue'),
  },

  {
    path: '/service-plans',
    name: 'service_plans',
    component: () => import('@/view/pages/ServicePlan/ServicePlans.vue'),
  },

  {
    path: '/service-plans/create',
    name: 'service_plans.create',
    component: () => import('@/view/pages/ServicePlan/CreateServicePlan.vue'),
  },

  {
    path: '/service-plans/:id',
    name: 'service_plans.edit',
    component: () => import('@/view/pages/ServicePlan/CreateServicePlan.vue'),
  },

  {
    path: '/warehouse-inventory',
    name: 'warehouse_inventory',
    component: () => import('@/view/pages/WarehouseInventory/WarehouseInventory.vue')
  },

  {
    path: '/offers',
    name: 'offers',
    component: () => import('@/view/pages/Offer/Offers.vue')
  },
  {
    path: '/offers/create',
    name: 'offers.create',
    component: () => import('@/view/pages/Offer/CreateOffer.vue')
  },
  {
    path: '/offers/:id',
    name: 'offers.edit',
    component: () => import('@/view/pages/Offer/CreateOffer.vue')
  },

  {
    path: '/sub-service-plans',
    name: 'sub_service_plans',
    component: () => import('@/view/pages/SubServicePlan/SubServicePlans.vue')
  },

  {
    path: '/sub-service-plans/create',
    name: 'sub_service_plans.create',
    component: () => import('@/view/pages/SubServicePlan/CreateSubServicePlan.vue')
  },
  {
    path: '/sub-service-plans/:id',
    name: 'sub_service_plans.edit',
    component: () => import('@/view/pages/SubServicePlan/CreateSubServicePlan.vue')
  },
  {
    path: '/sub-service-plans/:id',
    name: 'sub_service_plans.edit',
    component: () => import('@/view/pages/SubServicePlan/CreateSubServicePlan.vue')
  },
  {
    path: '/otps',
    name: 'otps',
    component: () => import('@/view/pages/OTP/ShowOTP.vue')
  },
  {
    path: '/job-otps',
    name: 'jobs_otps',
    component: () => import('@/view/pages/OTP/ShowJobOTP.vue')
  },

  {
    path: '/rentals/renting-partners',
    name: 'rentals.renting_partners',
    component: () => import('@/view/pages/Rentals/RentingPartner/Index.vue')
  },
  
  {
    path: '/rentals/renting-partner/:id',
    name: 'rentals.renting_partner.show',
    component: () => import('@/view/pages/Rentals/RentingPartner/Show.vue')
  },
  
  {
    path: '/rentals/rental-listings',
    name: 'rentals.rental_listings',
    component: () => import('@/view/pages/Rentals/RentalListing/RentalListings.vue')
  },

  {
    path: '/rentals/rental-listing/:id',
    name: 'rentals.rental_listing.show',
    component: () => import('@/view/pages/Rentals/RentalListing/ShowRentalListing.vue')
  },

  {
    path: '/rentals/rental-listing/create/:renting_partner_id',
    name: 'rentals.rental_listing.create',
    component: () => import('@/view/pages/Rentals/RentalListing/EditRentalListing')
  },

  {
    path: '/rentals/rental-listing/edit/:id',
    name: 'rentals.rental_listing.edit',
    component: () => import('@/view/pages/Rentals/RentalListing/EditRentalListing')
  },

  {
    path: '/dictionary/company',
    name: 'dictionary.company',
    component: () => import('@/view/pages/Dictionary/Company/Companies.vue')
  },

  {
    path: '/dictionary/age',
    name: 'dictionary.age',
    component: () => import('@/view/pages/Dictionary/Age/Age.vue')
  },

  {
    path: '/dictionary/category',
    name: 'dictionary.category',
    component: () => import('@/view/pages/Dictionary/Category/Categories.vue')
  },
  {
    path: '/dictionary/cycle-type',
    name: 'dictionary.cycle_type',
    component: () => import('@/view/pages/Dictionary/CycleType/CycleTypes.vue')
  },
  {
    path: '/dictionary/cycle-speed',
    name: 'dictionary.cycle_speed',
    component: () => import('@/view/pages/Dictionary/CycleSpeed/CycleSpeeds.vue')
  },
  {
    path: '/dictionary/cycle-wheel-size',
    name: 'dictionary.cycle_wheel_size',
    component: () => import('@/view/pages/Dictionary/CycleWheelSize/CycleWheelSizes.vue')
  },
  {
    path: '/dictionary/cycle-frame-material',
    name: 'dictionary.cycle_frame_material',
    component: () => import('@/view/pages/Dictionary/CycleFrameMaterial/CycleFrameMaterials.vue')
  },
  {
    path: '/dictionary/cycle-frame-size',
    name: 'dictionary.cycle_frame_sizes',
    component: () => import('@/view/pages/Dictionary/CycleFrameSize/CycleFrameSizes.vue')
  },

  {
    path: '/rentals/rental-accessory',
    name: 'rentals.rental_accessory',
    component: () => import('@/view/pages/Rentals/RentalAccessory/RentalAccessories.vue')
  },
  {
    path: '/rentals/rental-accessory/create',
    name: 'rentals.rental_accessory.create',
    component: () => import('@/view/pages/Rentals/RentalAccessory/EditRentalAccessory.vue')
  },
  {
    path: '/rentals/rental-accessory/:id',
    name: 'rentals.rental_accessory.edit',
    component: () => import('@/view/pages/Rentals/RentalAccessory/EditRentalAccessory.vue')
  },

  {
    path: '/rentals/rental-booking',
    name: 'rentals.rental_bookings',
    component: () => import('@/view/pages/Rentals/RentalBooking/RentalBookings.vue')
  },

  {
    path: '/rentals/rental-booking/:id',
    name: 'rentals.rental_booking.show',
    component: () => import('@/view/pages/Rentals/RentalBooking/ShowRentalBooking.vue')
  },

  {
    path: '/rentals/offers',
    name: "rentals.offers",
    component: () => import('@/view/pages/Rentals/RentalOffer/RentalOffers.vue')
  },
  {
    path: '/rentals/offers/create',
    name: "rentals.offers.create",
    component: () => import('@/view/pages/Rentals/RentalOffer/CreateRentalOffer.vue')
  },
  {
    path: '/rentals/offers/:id',
    name: "rentals.offers.edit",
    component: () => import('@/view/pages/Rentals/RentalOffer/CreateRentalOffer.vue')
  }

];
