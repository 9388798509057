import { createApp } from 'vue';
import App from '../App.vue';

import tag from "../components/tag/tag.vue";

const app = createApp(App);

app.component('status-tag', tag)


app.mixin({
    methods: {
        getStatusTagColor(status) {
            switch (status) {
                case 'started':
                    return '#C0CA33'
                case 'created':
                    return 'orange'
                case 'assigned':
                    return '#00ACC1' //cyan
                case 'finished':
                    return 'green'
                case 'cancelled':
                    return '#C62828' //red
            }
        },
    }
})

export default app;
